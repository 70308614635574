import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Container,
    Heading,
    Stack,
    StackDivider,
    Text,
    VStack
} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {GetOrderByOrderId} from '../api/ord-sever';

export function OrderStatus() {
    const orderId = useLocation().pathname.split('/')[2];
    const [orderData, setOrderData] = useState(null);
    const [invoiceId, setInvoiceId] = useState(null);

    useEffect(() => {
        GetOrderByOrderId(orderId).then((res) => {
            console.log(res);
            setOrderData(res.data);
            setInvoiceId(res.data.invoice_id);
        });
    }, [orderId]);


    const getOrderStatus = (status) => {
        switch (status) {
            case 1:
                return 'Waiting For Payment'
            case 2:
                return 'Payment Received (We are processing your order)'
            case 3:
                return 'Order Finished'
            case 4:
                return 'Order Canceled'
            case 5:
                return 'Order Processing'
        }
    }

    const getOrderHeaderStatus = (status) => {
        switch (status) {
            case 1:
                return 'Order will be processed soon after paid'
            case 2:
                return 'We have received your payment'
            case 3:
                return 'Your order has been processed successfully'
            case 4:
                return 'Order has been canceled'
            case 5:
                return 'Please wait while we processing your order'
        }
    }

    return (
        <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100vh">
            <VStack>
                <Text fontSize={"lg"}
                      fontFamily={"inherit"}>
                    {
                        getOrderHeaderStatus(orderData?.status)
                    }
                </Text>
                <Box p={8}>
                    <Card width={600}>
                        <CardHeader>
                            <Heading size='md'>Order Status</Heading>
                        </CardHeader>
                        <CardBody>
                            <Stack divider={<StackDivider/>} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        OrderID
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {orderId}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Amount
                                    </Heading>
                                    <Text pt='2' fontSize='sm' fontStyle='italic' color='green'>
                                        {orderData?.amount} BTC
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Order Status
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {getOrderStatus(orderData?.status)}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        btc address
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {orderData?.btc_address}
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        eth address
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {orderData?.eth_address}
                                    </Text>
                                </Box>
                                {
                                    orderData?.status === 3 ?
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Inscription
                                            </Heading>
                                            <Text pt='2' fontSize='sm' color={"blue"}>
                                                <a href={`https://ordinals.com/inscription/${orderData?.inscription}`}>
                                                    {orderData?.inscription}
                                                </a>
                                            </Text>
                                        </Box> : null

                                }
                                {
                                    orderData?.status === 3 ?
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                ETH Transaction
                                            </Heading>
                                            <Text pt='2' fontSize='sm' color={"blue"}>
                                                <a href={`https://etherscan.io/tx/${orderData?.eth_transaction}`}>
                                                    {orderData?.eth_transaction}
                                                </a>
                                            </Text>
                                        </Box> : null
                                }
                            </Stack>
                        </CardBody>
                    </Card>
                </Box>
                {
                    orderData?.status === 1 ?
                        <Button
                            colorScheme="brand"
                            onClick={() =>
                                (window.location.href = `https://pay.satmask.com/i/${invoiceId}`)
                            }>
                            Pay Now
                        </Button> : null
                }
            </VStack>
        </Container>
    );
}
