import React from 'react';
import GradientText from './GradientText';
import { Box, Textarea } from '@chakra-ui/react';

const DestAddress = props => {
  // const inputRef = useRef();
  return (
    <Box>
      <Box mt="4" mb="2" ml="1">
        <GradientText>{props.prompt}</GradientText>
      </Box>
      <Textarea
        ref={props.addressRef}
        size="sm"
        borderRadius="15"
        bg="white"
        overflow="hidden"
        _hover={{ boxShadow: 'none', border: '1px solid gray' }}
        _focus={{
          boxShadow: 'none',
          border: '2px solid teal',
          bg: 'gray.50',
        }}
        resize="none"
        minHeight="4rem"
        padding="1.3rem"
        fontSize={{ base: '8', md: '14' }}
        fontFamily="body"
        width="100%"
        onBlur={props.onBlur}
      />
    </Box>
  );
};

export default DestAddress;
