import GradientText from "./GradientText";
import {Box, Button, HStack, Input, useNumberInput, useToast} from "@chakra-ui/react";
import {useState} from "react";


export default function AmountInput(props) {
    const {
        getInputProps,
        getIncrementButtonProps,
        getDecrementButtonProps
    } = useNumberInput(props.inputLimit)
    const [isInvalid, setIsInvalid] = useState(false)
    const toast = useToast();

    const inc = getIncrementButtonProps()
    const dec = getDecrementButtonProps()
    const input = getInputProps()

    const changeHandler = (event) => {
        if (props.inputLimit !== undefined) {
            if (event.target.value === '') {
                setIsInvalid(true)
            } else {
                const number = Number(event.target.value)
                if (number > props.inputLimit.max || number < props.inputLimit.min) {
                    toast({
                        title: "Invalid amount",
                        description: `Please enter a amount between ${props.inputLimit.min} and ${props.inputLimit.max}`,
                        status: "error",
                    })
                    setIsInvalid(true)
                } else {
                    setIsInvalid(false)
                }
            }
        }
    }

    return (
        <Box mt="4" mb="2" ml="1">
            <GradientText mb="2"> Enter Brc20 Token Amount </GradientText>
            <HStack mt={1} maxW='320px'>
                <Input isInvalid={isInvalid}
                       border={'2px solid'}
                       borderColor={'cyan.400'}
                       placeholder={props.inputLimit !== undefined ?
                           `limit:   ${props.inputLimit.min}  ~  ${props.inputLimit.max}` : ``}
                       {...input}
                       ref={props.amount}
                       onBlur={changeHandler}/>
                <Button colorScheme="brand" {...inc}>+</Button>
                <Button colorScheme="brand" {...dec}>-</Button>
            </HStack>
        </Box>
    )
}

