import {NavBar} from '../components/NavBar';
import {Box} from '@chakra-ui/react';
import {Outlet} from 'react-router-dom';
import '../css/background.css';

// import "../css/synthetics.css"

export function RootLayout() {
    return (
        <Box
            display="flex"
            minHeight="100vh"
            flexDirection="column" // 确保导航栏在顶部
        >
            <Box marginTop={0} paddingTop={0}>
                <NavBar/>
            </Box>
            <Box className="root-layout" flexGrow={1}>
                <Outlet/>
            </Box>
        </Box>
    );
}
