import {Box, Button} from '@chakra-ui/react';
import React from 'react';

const BTCPayForm = props => {

    return (
        <Box mt={{base: '2', md: '5'}}
             style={{display: 'flex', justifyContent: 'center'}}>
            <Button colorScheme="brand"
                    isDisabled={props.isDisabled}
                    isLoading={props.isLoading}
                    onClick={props.onClick}>
                Inscribe
            </Button>
        </Box>
    );
};

export default BTCPayForm;
