import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Button,
  HStack,
  Input,
  Tooltip,
} from '@chakra-ui/react';
import { GradientText } from './GradientText';
import { ChevronDownIcon } from '@chakra-ui/icons';
import '../circle-question.svg';
import BlockchainFeeRate from '../api/blockchaininfo';

const NetworkFeeRateNew = ({ selectedFeeRate, setSelectedFeeRate }) => {
  const [feeRate, setFeeRate] = useState(null);
  const [customFeeRate, setCustomFeeRate] = useState(null);
  // const [selectedFeeRate, setSelectedFeeRate] = useState(null);

  useEffect(() => {
    BlockchainFeeRate()
      .then(data => {
        setFeeRate(data);
      })
      .catch(error => {
        console.error('Error fetching Blockchain fee rate:', error);
      });
  }, []);

  const handleClick = feeValue => {
    setSelectedFeeRate(feeValue || customFeeRate);
    console.log(selectedFeeRate);
  };

  const AccordionButtonWithRef = React.forwardRef((props, ref) => (
    <AccordionButton borderRadius="10px" ref={ref} {...props}>
      <GradientText>
        Fee Rate:
        {selectedFeeRate ? `${selectedFeeRate} sats/byte` : 'Custom'}{' '}
      </GradientText>
      <ChevronDownIcon color="#B838F6" />
    </AccordionButton>
  ));

  return (
    <Accordion allowMultiple mt="2">
      <AccordionItem>
        <Tooltip
          label="Fees include network fee on Bitcoin and token mapping fees on Ethereum network"
          bgColor="linear-gradient(to right, #B838F6, #D77BB6)"
          // justifyContent="right"
          placement="right"
          borderRadius="10"
        >
          <AccordionButtonWithRef />
        </Tooltip>
        <AccordionPanel>
          <HStack spacing={4} direction="row" justifyContent="stretch">
            <Button
              w="150px"
              h="50px"
              bg="linear-gradient(to right, #B838F6, #c14be4)"
              onClick={() => handleClick(feeRate?.['priority'])}
            >
              Priority {feeRate?.['priority']}
            </Button>
            <Button
              w="150px"
              h="50px"
              bg="linear-gradient(to right, #c14be4, #c85ad5)"
              onClick={() => handleClick(feeRate?.['regular'])}
            >
              Regular {feeRate?.['regular']}
            </Button>
            <Button
              w="150px"
              h="50px"
              bg="linear-gradient(to right, #c85ad5, #d06cc4)"
              onClick={() => handleClick(feeRate?.['limits']['min'])}
            >
              Slow {feeRate?.['limits']['min']}
            </Button>
            <Button
              w="150px"
              h="50px"
              bg="linear-gradient(to right, #d06cc4, #d77bb6)"
              onClick={() => handleClick(feeRate?.['limits']['min'])}
            >
              <Input
                fontWeight="bold"
                // justifyContent="center"
                placeholder="custom"
                _placeholder={{
                  color: 'white',
                  fontWeight: 'bold',
                  ml: '-2',
                }}
                // color="black"
                _hover={{
                  border: 'none',
                  // bg: 'linear-gradient(to right, #d06cc4, #d77bb6)',
                }}
                _focus={{ border: 'red', boxShadow: 'none' }}
                border="none"
                sx={{ textAlign: 'left' }}
                onChange={e => setSelectedFeeRate(e.target.value)}
              ></Input>
            </Button>
          </HStack>
        </AccordionPanel>
      </AccordionItem>
      {feeRate === null && <div></div>}
    </Accordion>
  );
};

export default NetworkFeeRateNew;
