import { Box, Center, Flex, IconButton, Spacer } from '@chakra-ui/react';
import { Logo } from '../Logo';
import MenuLinks from './MenuLink';
import React from 'react';
import '../css/navbar.css';
import { FaDiscord, FaTelegram, FaTwitter } from 'react-icons/all';

export function NavBar() {
  return (
    <Box
      as="nav"
      className="navbar"
      // shadow="md"
      // position="sticky"
      top={-3}
      // zIndex={10}
    >
      {/* <Flex alignItems="center" ml="18px" mr="18px" mt="10px" pb="16px" gap="2"> */}
      <Flex
        alignItems="center"
        // ml="18px"
        // mr="18px"
        // mt="10px"
        // pb="16px"
        // gap="2"
        // flexWrap="wrap"
        // minW={{ base: '90%', md: 'auto' }}
        maxW={{ base: '99%' }}
      >
        <Center>
          <Logo />
        </Center>
        <Spacer />
        <MenuLinks />
        <Spacer />
        {/* <ColorModeSwitcher/> */}
        {/*<ConnectWalletButton/>*/}
        <Flex gap={2}>
          <IconButton
            aria-label="telegram"
            as="a"
            target="_blank"
            href="https://t.me/SatmaskOfficial"
            icon={<FaTelegram />}
            variant="ghost"
          ></IconButton>
          <IconButton
            aria-label={'discord'}
            as="a"
            target="_blank"
            href="https://discord.gg/er73Pgjw"
            icon={<FaDiscord />}
            variant="ghost"
          ></IconButton>
          <IconButton
            aria-label={'twitter'}
            as="a"
            target="_blank"
            href="https://twitter.com/satmaskOfficial"
            icon={<FaTwitter />}
            variant="ghost"
          ></IconButton>
        </Flex>
      </Flex>
    </Box>
  );
}
