import React from 'react';
import { Button, Box } from '@chakra-ui/react';
import Web3 from 'web3';
import '../Satmasklogo.png';

function TokenAddButton() {
  // Initialize web3
  const web3 = new Web3(Web3.givenProvider);

  // Define the token details
  const tokenAddress = '0x27c7eF42Dc0d840942Dc57b20CA07afeFEc24E14'; // Replace with your token address
  const tokenSymbol = 'MASK'; // Replace with your token symbol
  const tokenDecimals = 18; // Replace with your token decimals
  const tokenImage = 'Satmasklogo.png'; // Replace with your token logo URL
  // Define the mainnet id as a hex string
  const mainnetIdHex = '0x1';

  // Define the function to add the token to metamask
  const addToken = async () => {
    try {
      // Check if metamask is installed and connected
      if (window.ethereum && web3.currentProvider.isMetaMask) {
        // Request user account access
        await window.ethereum.request({ method: 'eth_requestAccounts' });

        // check the current network id
        const networkId = await web3.eth.net.getId();

        //if thenetworkis not mainne, switch it to mainnet
        // If the network is not mainnet, switch to mainnet
        if (networkId !== mainnetIdHex) {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: mainnetIdHex }],
          });
        }

        // Add the token to metamask
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenImage,
            },
          },
        });
      } else {
        // Alert the user to install metamask
        alert('Please install MetaMask to use this feature.');
      }
    } catch (error) {
      // Handle any errors
      console.error(error);
    }
  };

  return (
    <Box
      mt={{ base: '0', md: '3' }}
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      <Button colorScheme="brand" onClick={addToken}>
        Add Token Into Metamask
      </Button>
    </Box>
  );
}

export default TokenAddButton;
