import { SwapWidget } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css';
import { Box } from '@chakra-ui/react';

const mask = '0x27c7eF42Dc0d840942Dc57b20CA07afeFEc24E14';
const usdt = '0xdac17f958d2ee523a2206206994597c13d831ec7';
const TOKEN_LIST = [
  {
    name: 'Brc20 BITS',
    address: mask,
    symbol: 'MASK',
    decimals: 18,
    chainId: 1,
    logoURI: 'https://satmask.com/favicon.ico',
  },
];

export default function Uniswap() {
  return (
    <Box className="Uniswap">
      <SwapWidget
        width={360}
        defaultInputAmount={1}
        defaultInputTokenAddress={usdt}
        defaultOutputTokenAddress={mask}
        tokenList={TOKEN_LIST}
      />
    </Box>
  );
}
