import {extendTheme} from '@chakra-ui/react';

const theme = extendTheme({
    colors: {
        brand: {
            500: 'linear-gradient(to bottom, #B838F6 0%, #D77BB6 100%)',
        },
    },
    components: {
        Button: {
            baseStyle: {
                backgroundImage: 'linear-gradient(to right, #9795f0 0%, #D77BB6 100%)',
                backgroundSize: '100% 100%',
                color: 'white',
                borderRadius: 'md',
                _hover: {
                    backgroundPosition: 'bottom center',
                },
                _active: {
                    backgroundPosition: 'top center',
                },
            },
        },
    },
});

export default theme;
