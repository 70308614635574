import {Box} from '@chakra-ui/react';

export function GradientText(props) {
    return (
        <Box
            // as="span"
            backgroundImage="linear-gradient(to bottom, #B838F6, #D77BB6)"
            backgroundClip="text"
            color="transparent"
            fontWeight="bold"
            // fontSize="5xl"
            fontFamily="'Roboto Slab', serif"
            display="flex"
            alignContent="center"
            alignItems="center"
        >
            {props.children}
        </Box>
    );
}

export default GradientText;
