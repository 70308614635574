import axios from 'axios';

const Bitgo = axios.create({
    baseURL: `https://www.bitgo.com`,
});

const BitgoFeeRate = name => {
    return new Promise((resolve, reject) => {
        Bitgo.get(`/api/v2/btc/tx/fee`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export default BitgoFeeRate;
