import React from 'react';

function BRC20Page() {
    return (
        <iframe
            src="https://brc-20.io"
            title="BRC20"
            width="100%"
            height="650"
            seamless="0"
        ></iframe>
    );
}

export default BRC20Page;
