import React from 'react';
import {Flex, Stack} from '@chakra-ui/react';
import {Link, NavLink} from 'react-router-dom';
import '../css/menulink.css';
import '../css/PS2P.css';

const MenuLinks = () => {
    return (
        <Flex flex="1" className="menulink" mt={['2', '2', '0', '0']}>
            <Stack
                spacing={[4, 4, 6, 8]} // 修改间距
                align="center"
                justify="center" // 修改对齐方式
                direction={['row', 'row', 'row', 'row']}
                pt={[0, 0, 0, 0]}
                // maxW="1200px"   // 限制菜单项展示的宽度
            >
                <NavLink
                    to={'/'}
                    fontSize={['sm', 'sm', 'md', 'md']}
                >
                    Home
                </NavLink>{' '}
                <NavLink
                    to={'/inscribe'}
                    fontSize={['sm', 'sm', 'md', 'md']}
                >
                    Inscribe
                </NavLink>
                <NavLink
                    to={'/BRC20'}
                    fontSize={['sm', 'sm', 'md', 'md']}
                >
                    Brc20
                </NavLink>
                <Link to="https://docs.satmask.com" target="_blank">
                    Docs
                </Link>
            </Stack>
        </Flex>
    );
};

export default MenuLinks;
