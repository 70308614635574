import { Box, Button, Container, HStack, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { GradientText } from '../components/GradientText';
import { HomePhrase } from '../components/HomePhrase';
import '../css/home-phrase.css';
import '../css/inscribe-button.css';
import Uniswap from '../components/uniswap';
import TokenAddButton from '../components/TokenAddButton';

export function Home() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="calc(100vh - 1rem)"
      // bgGradient="linear(to-r, teal.500, green.500)"
      // color="inherit"
    >
      <Container
        maxW="container.xl"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        textAlign="center"
        marginTop="-10rem"
      >
        <Text
          fontSize={['3xl', '4xl', '5xl', '5xl']}
          lineHeight={['1.2', '1.2', '1.2', '1.2']}
          fontWeight="bold"
          mb={6}
          fontFamily="'Roboto Slab', serif"
          mt="40"
          color={'#B838F6'}
        >
          <GradientText>Mapping BRC20 To ERC20</GradientText>
        </Text>
        <HStack>
          <Box width={['80%', '50%']} margin="0 auto">
            <p style={{ color: 'black', opacity: 0.4, textAlign: 'justify' }}>
              Satmask is developing an open-source project that utilizes the
              Bitcoin Ordinals protocol. The project offers an innovative
              mapping ERC20 service to provide superior liquidity to BRC20
              tokens.
            </p>
            <p style={{ color: 'black', opacity: 0.4, textAlign: 'justify' }}>
              The project also offers a decentralized exchange that allows users
              to trade BRC20 tokens for ERC20 tokens, with carefully designed
              engineering.
            </p>
            <p style={{ color: 'black', opacity: 0.4, textAlign: 'justify' }}>
              Hold the edge of the Bitcoin Ordinals protocol. Enjoy the Ethereum
              network with Satmask. Keep the token value and mapping service
              based on Bellman's Principle.
            </p>
            <Box mt={'20'}>
              <HomePhrase />
              <NavLink to="/inscribe">
                <Button
                  mt="10"
                  colorScheme="brand"
                  bg="brand.500"
                  color="white"
                >
                  Inscribe And Trading Now!
                </Button>
                <TokenAddButton />
              </NavLink>
            </Box>
          </Box>
          <Uniswap />
        </HStack>
      </Container>
    </Box>
  );
}
