import axios from 'axios';

const OrdSever = axios.create({
    baseURL: `https://api.satmask.com/`,
});


export const SearchName = name => {
    return new Promise((resolve, reject) => {
        OrdSever.get(`/ordinals/searchName/${name}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
};

export const CreateInscriptionOrder = data => {
    return new Promise((resolve, reject) => {
        OrdSever.post(`/order/createInscriptionOrder`, data)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export const GetOrderByOrderId = orderId => {
    return new Promise((resolve, reject) => {
        OrdSever.get(`/order/getOrderById/${orderId}`)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
    });
}