import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import '../css/home-phrase.css';

export const HomePhrase = () => {
  return (
    <Stack
      mt={{ base: '6', md: '10' }}
      direction={{ base: 'row', md: 'row' }}
      spacing={{ base: '2', md: '10' }}
      justify={{ md: 'center' }}
    >
      <Box className="home-phrase" mb={{ base: '2', md: '0' }}>
        Bellman's Principle
      </Box>
      <Box className="home-phrase" mb={{ base: '2', md: '0' }}>
        Built on Ordinals
      </Box>
      <Box className="home-phrase" mb={{ base: '2', md: '0' }}>
        Liquidity Provision
      </Box>
      <Box className="home-phrase" mb={{ base: '2', md: '0' }}>
        Censorship-resistant
      </Box>
    </Stack>
  );
};
