import {useEffect} from 'react';
import {Button} from '@chakra-ui/react';

function CoinButton(props) {

    const handleClick = () => {
        // 向父组件传递按钮点击事件
        props.onClick(props.id);
    };

    useEffect(() => {
        // 根据选中状态设置按钮颜色
        const bgColor = props.selectedCoin === props.id
            ? 'linear-gradient(to right, #c85ad5, #d06cc4)'
            : 'gray';
        document.getElementById(props.id).style.background = `${bgColor}`;
    }, [props.selectedCoin, props.id]);

    return (
        <Button
            id={props.id}
            isDisabled={props.disabled}
            className="inscribe-button"
            flex="1"
            onClick={handleClick}
            bgColor="gray"
        >
            {props.children}
        </Button>
    );
}

export default CoinButton;
