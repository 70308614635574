import React from 'react';
import {Image, keyframes} from '@chakra-ui/react';
// import logo from './logo.png';
import logo from './satmask-logo-zip-file/svg/logo-black.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Logo = () => {
    return (
        <Image src={logo} alt="Logo" width="100px" height="100px" ml="20px"/>
    );
};
