import React from 'react';
import {ChakraProvider} from '@chakra-ui/react';
import {Home} from './pages/Home';
import {Route, Routes} from 'react-router-dom';
import {RootLayout} from './layouts/RootLayout';
import {Docs} from './pages/Docs';
import {Inscribe} from './pages/Inscribe';
import {Brc20} from './pages/Brc20';
import {OrderStatus} from './pages/OrderStatus';
import theme from './layouts/theme';

function App() {
    return (
        <ChakraProvider theme={theme}
                        toastOptions={{
                            defaultOptions: {
                                position: 'top',
                                isClosable: true,
                            }
                        }}>
            <Routes>
                <Route path="/" element={<RootLayout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="inscribe" element={<Inscribe/>}/>
                    <Route path="brc20" element={<Brc20/>}/>
                    <Route path="docs" element={<Docs/>}/>
                    <Route path="/order/:orderId" element={<OrderStatus/>}/>
                </Route>
            </Routes>
        </ChakraProvider>
    );
}

export default App;

// theme={theme}
