import {
  Box,
  Container,
  Flex,
  Stack,
  useBoolean,
  useToast,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { ArrowDownIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import NetworkFeeRate from '../components/NetworkFeeRate';
import NetworkFeeRateNew from '../components/NetworkFeeRateNew';
import BTCPayForm from '../components/BTCPayForm';
import CoinButton from '../components/CoinButton';
import { GradientText } from '../components/GradientText';
import DestAddress from '../components/DestAddress';
import '../css/shadow-box.css';
import '../css/stick-note.css';
import AmountInput from '../components/AmountInput';
import { CreateInscriptionOrder } from '../api/ord-sever';
import TokenAddButton from '../components/TokenAddButton';

export function Inscribe() {
  const [isLoading, setIsLoading] = useBoolean(false);
  const [coin, setCoin] = useState('');
  const [selectedFeeRate, setSelectedFeeRate] = useState(null);
  const BTCAddressRef = useRef('');
  const ETHAddressRef = useRef('');
  const Amount = useRef('');
  const coinLimits = new Map();
  const toast = useToast();
  const navigate = useNavigate(); // 获取navigate函数

  coinLimits.set('mask', {
    step: 1,
    defaultValue: 1,
    min: 1,
    max: 10,
    precision: 0,
  });
  coinLimits.set('BRUH', {
    step: 1,
    defaultValue: 1,
    min: 1,
    max: 100,
    precision: 0,
  });

  const handleCoinClick = id => {
    setCoin(id);
  };

  const validBTCAddress = /^bc1[a-zA-HJ-NP-Z0-9]{3,}$/;

  const checkBtcAddress = event => {
    if (!validBTCAddress.test(event.target.value)) {
      toast({
        title: 'Invalid BTC address',
        description: 'Please enter a valid BTC Taproot address',
        status: 'warning',
      });
    }
  };

  const checkEthAddress = event => {
    if (!event.target.value.startsWith('0x')) {
      toast({
        title: 'Invalid ETH address',
        description: 'Please enter a valid ETH address',
        status: 'warning',
        color: '#B838F6',
      });
    }
  };

  const handleInscribeSubmit = async () => {
    if (coin === '') {
      toast({
        title: 'No coin selected',
        description: 'Please select a coin',
        status: 'error',
      });
      return;
    }
    if (Amount.current.value === '') {
      toast({
        title: 'No amount selected',
        description: 'Please enter a amount',
        status: 'error',
      });
      const number = Number(Amount.current.value);
      const inputLimit = coinLimits.get(coin);
      if (number > inputLimit.max || number < inputLimit.min) {
        toast({
          title: 'Invalid amount',
          description: `Please enter a amount between ${inputLimit.min} and ${inputLimit.max}`,
          status: 'error',
        });
      }
      return;
    }
    if (BTCAddressRef.current.value === '') {
      toast({
        title: 'Invalid BTC address',
        description: 'Please enter a valid BTC address',
        status: 'error',
      });
      return;
    }
    if (ETHAddressRef.current.value === '') {
      toast({
        title: 'Invalid ETH address',
        description: 'Please enter a valid ETH address',
        status: 'error',
      });
      return;
    }
    if (selectedFeeRate === null) {
      toast({
        title: 'No fee rate selected',
        description: 'Please select a fee rate',
        status: 'error',
      });
      return;
    }
    const brc20_token = JSON.stringify({
      p: 'brc-20',
      op: 'mint',
      tick: `${coin}`,
      amt: `${Amount.current.value}`,
    });
    console.log(brc20_token);

    const data = {
      btc_address: BTCAddressRef.current.value,
      eth_address: ETHAddressRef.current.value,
      inscription_type: 2,
      brc20_token: brc20_token,
      fee_rate: selectedFeeRate / 1000,
    };

    console.log(data); //检查数据是否正确
    setIsLoading.on();
    CreateInscriptionOrder(data)
      .then(res => {
        // 如果Inscribe请求成功，则将isInscribed状态设置为true
        navigate(`/order/${res.data.order_id}`);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading.off();
      });
  };
  return (
    <Container mt="100">
      <Container className="shadow-box">
        <GradientText>Pick up your brc20 token below</GradientText>
        <Stack direction="row" mb="1" mt="1" isInline>
          <CoinButton id="mask" onClick={handleCoinClick} selectedCoin={coin}>
            MASK
          </CoinButton>
          <CoinButton
            id="BRUH"
            onClick={handleCoinClick}
            disabled
            selectedCoin={coin}
          >
            BRUH
          </CoinButton>
          <CoinButton
            id="gold"
            onClick={handleCoinClick}
            disabled
            selectedCoin={coin}
          >
            gold
          </CoinButton>
          <CoinButton
            id="sats"
            onClick={handleCoinClick}
            disabled
            selectedCoin={coin}
          >
            sats
          </CoinButton>
        </Stack>
        <AmountInput amount={Amount} inputLimit={coinLimits.get(coin)} />
        <DestAddress
          prompt={' BTC Taproot address receiver'}
          addressRef={BTCAddressRef}
          onBlur={checkBtcAddress}
        />
      </Container>
      <Flex align="center" justify="center" mt="2" mb="2">
        <ArrowDownIcon color="#D77BB6" fontWeight="bold" boxSize={6} />
      </Flex>
      <Container className="shadow-box">
        <Box mt="-5">
          <DestAddress
            prompt={' ETH address receiver'}
            addressRef={ETHAddressRef}
            onBlur={checkEthAddress}
          />
        </Box>
        <NetworkFeeRateNew
          selectedFeeRate={selectedFeeRate}
          setSelectedFeeRate={setSelectedFeeRate}
        />
      </Container>
      <Stack direction="column" spacing={4} align="center">
        <BTCPayForm
          onClick={handleInscribeSubmit}
          isDisabled={false}
          isLoading={isLoading}
        />
      </Stack>
    </Container>
  );
}
