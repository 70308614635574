import {Box} from '@chakra-ui/react';
import BRC20Page from '../components/BRC20Page';

export function Brc20() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="calc(100vh - 1rem)"
            borderColor="linear-gradient(to top, #9795f0 0%, #D77BB6 100%)"
            // bgGradient="linear(to-r, teal.500, green.500)"
            color="linear-gradient(to top, #9795f0 0%, #D77BB6 100%)"
        >
            {/* <Container
        maxW="200px"
        h="100px"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgGradient: 'linear-gradient(to top, #9795f0 0%, #D77BB6 100%)',
          border: '2px solid',
          borderColor: 'linear-gradient(to top, #9795f0 0%, #D77BB6 100%)',
          borderRadius: 'md',
          textAlign: 'center',
          fontSize: '5xl',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: 'widest',
        }}
      >
        Coming Soon
      </Container> */}
            <BRC20Page></BRC20Page>
        </Box>
    );
}

export default Brc20;
