import axios from 'axios';

const Blockchain = axios.create({
  baseURL: `https://api.blockchain.info`,
});

const BlockchainFeeRate = () => {
  return new Promise((resolve, reject) => {
    Blockchain.get(`/mempool/fees`)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export default BlockchainFeeRate;
